import React, { useEffect, useState } from 'react'
import { SBFloorPlan } from '@digitalworkflow/sbpcommon'
import BuildingSelection from './BuildingSelection'
import { syncDatabase } from '../utils/databaseFunctions'

const SbFloor = () => {
  const [isSyncing, setIsSyncing] = useState(true)
  const [presentBuildingId, setPresentBuildingId] = useState('')

  useEffect(() => {
    syncDatabase(setIsSyncing)
  }, [])
  return !isSyncing ? (
    <>
      {presentBuildingId === '' ? (
        <BuildingSelection setPresentBuildingId={setPresentBuildingId} />
      ) : (
        <>
          <button onClick={() => setPresentBuildingId('')}>Go Back</button>
          <SBFloorPlan buildingId={presentBuildingId} editMode />
        </>
      )}
    </>
  ) : (
    <h1>Syncing1</h1>
  )
}
export default SbFloor
