import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AdminPortalLayout } from '@digitalworkflow/sbpcommon'
import { profileMenuItems, sidebarMenu } from './constant'

import logo from './assets/images/bgis-logo.png'
import logoSmImage from './assets/images/bgis-logo-sm.svg'

import '@digitalworkflow/sbpcommon/dist/index.css'
import SbFloor from './components/SbFloor'
import {
  CommonHelper,
  PSL,
  PSLHelper
} from '@digitalworkflow/dwtranslateclient'

function App() {
  const [isPSLIntialize, setIsPSLIntialize] = useState<boolean>(true)

  useEffect(() => {
    Initialization()
  }, [])
  const Initialization = async () => {
    setIsPSLIntialize(true)
    // Default's to the staging server which will work
    // for all environments unless a firewall blocks staging
    if (process.env.PSL_ENDPOINT) PSL.SetPSLEndpoint(process.env.PSL_ENDPOINT)
    const envName =
      process.env.REACT_APP_ENV || process.env.REACT_APP_PSL_ENV || ''
    console.log(
      'Initializing DWPortal with Environment: ',
      process.env.REACT_APP_PSL_ENV
    )
    PSL.SetProjectName('dwportal')
    PSL.SetEnvironment(envName)
    const envType = PSL.GetEnvironment()
    CommonHelper.setEnvironment(envType)
    await PSLHelper.initialize()
    setIsPSLIntialize(false)
  }

  if (isPSLIntialize) {
    return <h1>Loading</h1>
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <AdminPortalLayout
              menuItems={sidebarMenu}
              profileMenuItems={profileMenuItems}
              logoImage={logo}
              logoSmImage={logoSmImage}
              username={'Imran Haider'}
            />
          }
        >
          {/* <Route path='' element={<SBFloorPlan buildingId='' editMode/>} /> */}
          <Route path='' element={<SbFloor />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
