import { getRxStorageIndexedDB } from 'rxdb-premium/plugins/storage-indexeddb'
import {
  CommonHelper,
  LocalDatabaseManager
} from '@digitalworkflow/dwtranslateclient'
/**
 * Initiates synchronization of the local database with the server.
 * @param setWidth Function to set the synchronization progress as a percentage.
 * @param setSyncing Function to set the syncing state (true/false).
 */
export const syncDatabase = async (
  setIsSyncing: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  CommonHelper.setEnvironment('dev' as any)

  CommonHelper.setProjectName('dwportal')

  const instance = LocalDatabaseManager.instance()
  if (instance.db && !instance.db.destroyed) {
    return
  }

  await LocalDatabaseManager.startDb(getRxStorageIndexedDB(), '')

  const totalCollections = instance.getCollectionsWithReplication()
  let collections: string[] = totalCollections.map((collection) =>
    collection.getCollectionName()
  )
  const syncEvents = instance.evDatabaseSyncProgress()

  syncEvents.colletionSyncComplete$.subscribe((collection: string) => {
    if (collection) {
      // console.log('collection Sync complete: ', collection)
      collections = collections.filter((c) => c !== collection)
    }
  })

  syncEvents.initialPullComplete$.subscribe(async (isComplete: boolean) => {
    if (isComplete) {
      console.log('Initial pull replication is complete.')
      setIsSyncing(false)
      await instance.isBusy()
    }
  })

  syncEvents.error$.subscribe((error: any) => {
    if (error !== null && error !== undefined) {
      console.error('Replication error:', error)
    }
  })

  await instance.isBusy()
}
