import React from 'react'
import { SelectComponentPropTypes } from './types'
import './styles.selectcomponent.scss' // Import the Sass file

/**
 *
 * @component
 * @example
 * const items = [
 *   { value: '1', label: 'Option 1' },
 *   { value: '2', label: 'Option 2' },
 *   { value: '3', label: 'Option 3' }
 * ];
 *
 * <SelectComponent
 *   label="Option"
 *   items={items}
 *   errorMessage="This field is required"
 *   required
 *   onChange={handleChange}
 * />
 *
 * @param {SelectComponentPropTypes} props - The properties for the select component.
 * @param {string} props.label - The label to display above the select field.
 * @param {string} [props.errorMessage] - The error message to display below the select field. Defaults to a generic required message if not provided.
 * @param {Array<{ value: string | number, label: string }>} props.items - An array of options for the select field, where each option includes a `value` and `label`.
 * @param {React.SelectHTMLAttributes<HTMLSelectElement>} [props] - Additional properties to pass to the `select` element, such as `value`, `onChange`, etc.
 * @returns {JSX.Element} The rendered select component.
 */
const SelectComponent = ({
  label,
  errorMessage,
  items,
  parentClassName,
  ...props
}: SelectComponentPropTypes) => {
  return (
    <div className={`select-component column ${parentClassName}`}>
      {label && (
        <label htmlFor='validationCustom04' className='select-label'>
          {label}
        </label>
      )}
      <select
        {...props}
        className='select-field'
        id='validationCustom04'
        value={
          typeof props.value === 'number' ? props.value.toString() : props.value
        }
      >
        <option value=''>{`Please select ${label}`}</option>
        {items.map((i, index) => (
          <option key={index} value={i.value}>
            {i.label}
          </option>
        ))}
      </select>
      <div className='invalid-feedback'>
        {errorMessage || `${label} is Required!`}
      </div>
    </div>
  )
}

export default SelectComponent
